export default class {
    constructor({
        elId,
        navItemsHandle,
        navTogglesHandle,
        activeClass,
    }) {
        // Elements and class variables
        const el = document.getElementById(elId);
        const navItems = el.querySelectorAll(navItemsHandle);
        const navBtns = el.querySelectorAll(navTogglesHandle);

        // Event handler functions
        function handleClick(e) {
            e.preventDefault();

            if (this.parentNode.classList.contains(activeClass)) {
                this.parentNode.classList.remove(activeClass);
            } else {
                Array.from(navItems).forEach(l => {
                    l.classList.remove(activeClass);
                });

                this.parentNode.classList.add(activeClass);
            }
        }

        // Event listeners
        Array.from(navBtns).forEach(btn => {
            btn.addEventListener('click', handleClick);
        });

        // Initialize
    }
}
