import Swiper, { Autoplay } from 'swiper';

export default class {
    constructor({
        elId,
        swiperHandle,
    }) {
        // Elements and class variables
        const el = document.getElementById(elId);
        const handle = el.querySelector(swiperHandle);

        // State variables

        // Event handler functions

        // Event listeners

        // Initialize
        if (handle) {
            new Swiper(handle, { // eslint-disable-line no-new
                modules: [Autoplay],
                autoplay: {
                    delay: 10000,
                },
                autoHeight: true,
                direction: 'vertical',
                loop: true,
                speed: 600,
            });
        }
    }
}
