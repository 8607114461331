import { scrollTo } from '../utilities/scroll';

export default class {
    constructor({
        elId,
        scrollHandle,
    }) {
        // Elements and class variables
        const el = document.getElementById(elId);
        const scroll = el.querySelector(scrollHandle);

        // State variables

        // Event handler functions
        function handleClick(e) {
            e.preventDefault();
            scrollTo(scroll.getAttribute('href').replace(/^#/, ''));
        }

        // Enable event listeners
        if (scroll) scroll.addEventListener('click', handleClick);

        // Initalize
    }
}
