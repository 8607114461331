export default class {
    constructor({
        elId,
        csrfTokenName,
        csrfTokenValue,
        userId,
        activeClass = 'is-active',
    }) {
        // Elements and class variables
        const el = document.getElementById(elId);
        const removes = el.querySelectorAll('ul > li > button');
        const input = el.querySelector('input[type="text"]');
        const menuItems = el.querySelectorAll('menu > li');
        const add = el.querySelector('fieldset > button');
        const form = el.closest('form');

        function addCompany(id) {
            el.insertAdjacentHTML(
                'beforeend',
                `<input type="hidden" name="fields[userCompanies][]" value="${id}">`,
            );
        }
        function removeCompany(id) {
            el.querySelector(`input[type="hidden"][value="${id}"]`).remove();
        }
        function submitForm() {
            form.setAttribute('action', '#companies');
            form.submit();
        }

        removes.forEach(r => {
            r.onclick = () => {
                removeCompany(r.parentElement.dataset.id);
                submitForm();
            };
        });
        input.onkeyup = ({ key }) => {
            if (key === 'Enter') {
                add.click();

                return;
            }

            menuItems.forEach(mi => {
                const matches = mi.innerText.toLowerCase().includes(input.value.toLowerCase());

                mi.classList.toggle(activeClass, input.value && matches);
            });
            add.disabled = input.value.length === 0;
        };
        input.onclick = e => { e.stopPropagation(); };
        menuItems.forEach(mi => {
            mi.onclick = e => {
                e.stopPropagation();

                addCompany(mi.dataset.id);
                submitForm();
            };
        });
        add.onclick = () => {
            add.setAttribute('disabled', 'disabled');

            fetch('/actions/forms-module/forms/add-company', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    [csrfTokenName]: csrfTokenValue,
                    name: input.value,
                    userId,
                }),
            }).then(response => {
                if (!response.ok) {
                    throw new Error('Network response error.');
                }

                return response.json();
            }).then(({ success, id }) => {
                if (!success) {
                    throw new Error('Unable to create new company.');
                }

                addCompany(id);
                submitForm();
            }).catch(error => {
                add.removeAttribute('disabled');
                window.alert(`Error: ${error}`); // eslint-disable-line no-alert
            });
        };
    }
}
