import { scrollTo } from "../utilities/scroll";

export default class {
    constructor({
        href,
        name,
        seatCode,
        downstairsHandle,
        upstairsHandle,
        svgHandle,
    }) {
        // Elements and class variables
        const floorId = seatCode.startsWith('D') ? downstairsHandle : upstairsHandle;
        const floor = document.getElementById(floorId);
        const svgCont = floor.querySelector(svgHandle);
        const svg = svgCont.getElementsByTagName('svg');
        const seat = document.getElementById(seatCode) || document.querySelector('[id^="'+ seatCode +'_"]');

        // get coordinates of selected seat with svg transforms applied
        // https://stackoverflow.com/a/6860876
        function getCoordinates() {
            const seatPos = seat.getBBox();
            const seatMatrix = seat.getCTM();
            let coordinates = svg[0].createSVGPoint();
            coordinates.x = seatPos.x;
            coordinates.y = seatPos.y;

            return coordinates.matrixTransform(seatMatrix);
        }

        function injectLink() {
            const coordinates = getCoordinates();
            const tag = href === '' ? 'span' : 'a';
            const el = document.createElement(tag);

            if (tag === 'a') {
                el.href = href;
            }
            el.textContent = name;

            svgCont.appendChild(el);
            el.style.top = coordinates.y - (el.offsetHeight * 1.2) + 'px';
            el.style.left = coordinates.x - (el.offsetWidth * 0.47) + 'px';
        }

        // Event handler functions

        // Event listeners

        // Initialize
        injectLink();
        scrollTo(floorId, 40);
    }
}
