import Swiper, { Navigation, Pagination } from 'swiper';

export default class {
    constructor({
        elId,
        swiperHandle,
        paginationHandle,
        prevHandle,
        nextHandle,
    }) {
        // Elements and class variables
        const el = document.getElementById(elId);
        const handle = el.querySelector(swiperHandle);

        // State variables

        // Event handler functions

        // Event listeners

        // Initialize
        if (handle) {
            new Swiper(handle, { // eslint-disable-line no-new
                modules: [Navigation, Pagination],
                autoHeight: false,
                grabCursor: true,
                loop: true,
                navigation: {
                    nextEl: nextHandle,
                    prevEl: prevHandle,
                },
                pagination: {
                    el: paginationHandle,
                    type: 'fraction',
                },
                slidesPerView: 'auto',
                spaceBetween: 10,
            });
        }
    }
}
