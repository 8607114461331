import { scrollTo } from '../utilities/scroll';

export default class {
    constructor({
        elId,
        scrollHandle,
    }) {
        // Elements and class variables
        const el = document.getElementById(elId);
        const scrollBtns = el.querySelectorAll(scrollHandle);

        // Event handler functions
        function handleClick(e) {
            e.preventDefault();
            scrollTo(e.currentTarget.href.split('#')[1]);
        }

        // Event listeners
        scrollBtns.forEach(btn => {
            btn.addEventListener('click', handleClick);
        });

        // Initialize
    }
}
